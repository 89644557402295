import React, { useState, useEffect } from "react";
import { useMainContext } from "../../../contexts/MainContext";
import Pagination from "../../../components/pagination/Index";
import * as contractService from "../../../services/ContractService";
import Loading from "../../../components/loading/Index";
import NotFound from "../../../components/notfound/Index";
import { PAGE_INDEX, PAGE_SIZE_COUNTS } from "../../../constants/Pagination";
import * as Alerts from "../../../utils/Alerts";
import { useOrganization } from "../../../helper/useOrganization";
import { Link } from "react-router-dom";
import PageSizeSelect from "../../../components/pageSizeSelect/Index";
import { URL } from "../../../services/core/Urls";
import { useTranslation } from "react-i18next";
import { useTokenData } from "../../../helper/useTokenData";
import { useStudent } from "../../../helper/useStudent";
import ActionButtons from "../../../components/actionButton/Index";
import * as UserTypes from "../../../constants/UserType";
import FilterTableRow from "../../../components/tableFilterRow/Index";

export default function Index() {
  const { state, setState } = useMainContext();
  const [contractEducationAbroads, setContractEducationAbroads] = useState([]);
  const [selectedRow, setSelectedRow] = useState(0);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({});
  const [filter, setFilter] = useState({ common: null });
  const [isFirstRendered, setIsFirstRendered] = useState(true);

  const alias = useOrganization();
  const id = useStudent();
  const tokenData = useTokenData();
  const { t, i18n } = useTranslation();

  const isSelectRow = (rowId) => {
    rowId == selectedRow ? setSelectedRow(0) : setSelectedRow(rowId);
  };

  useEffect(() => {
    getContractEducationAbroads(state.currentPage, localStorage.rowCount);
  }, [localStorage.branch]);

  useEffect(() => {
    if (isFirstRendered) {
      setIsFirstRendered(false);
    } else {
      if (state?.contractEducationAbroadFilter != null) {
        const timer = setTimeout(() => {
          getContractEducationAbroads(PAGE_INDEX, localStorage.rowCount);
        }, 1500);

        return () => clearTimeout(timer);
      }
    }
  }, [state?.contractStudentFilter]);

  const getContractEducationAbroads = async (pageIndex, pageSize) => {
    setLoading(true);
    let result = await contractService.getContractEductionAbroadsByBranch(pageIndex, pageSize, localStorage.branch, state?.contractEducationAbroadFilter);
    if (result.success) {
      setContractEducationAbroads(result.data.datas);
      setPagination({
        pageIndex: result.data.pageIndex,
        hasNextPage: result.data.hasNextPage,
        hasPreviousPage: result.data.hasPreviousPage,
        totalPageCount: result.data.totalPageCount,
        totalRecordCount: result.data.totalRecordCount,
      });
      setLoading(false);
    } else {
      Alerts.error(result.message);
    }
  };

  const deleteContractEducationAbroad = async (id) => {
    await Alerts.confirmForDelete(async function (confirmed) {
      if (confirmed) {
        let result = await contractService.deleteContractEducationAbroad(id);
        if (result.success) {
          setSelectedRow(0);
          Alerts.success(result.message);
        } else {
          Alerts.error(result.message);
        }
        getContractEducationAbroads(pagination.pageIndex, localStorage.rowCount);
      }
    });
  };

  const changePage = (pageIndex) => {
    setState({ ...state, currentPage: pageIndex });
    getContractEducationAbroads(pageIndex, localStorage.rowCount);
  };

  const Search = (e) => {
    e.preventDefault();
    getContractEducationAbroads(PAGE_INDEX, localStorage.rowCount);
  };

  return (
    <div className="col-md-12">
      {tokenData.userTypeId != UserTypes.STUDENT_USER && <ActionButtons deletePath={`contract/educationAbroad/delete`} editPath={`contract/educationAbroad/edit`} addPath={`contract/educationAbroad/add`} exportPath={`contract/educationAbroad/export`} deleteRow={deleteContractEducationAbroad} selectedRow={selectedRow} />}

      <div className="card" data-background-color={state.theme}>
        <div className="card-header card-header-icon" data-background-color={state.theme}>
          <i className="material-icons">receipt_long</i>
        </div>
        <div className="card-content">
          <h4 className="card-title">{"Xaricdə təhsil müqavilələri"}</h4>
          <div className="row">
            <div className="col-md-6 f-left">
              <PageSizeSelect onChange={(e) => getContractEducationAbroads(PAGE_INDEX, e.value)} />
            </div>
            <form onSubmit={Search}>
              <div className="navbar-form col-md-6 f-right">
                <div className="form-group form-search navbar-form">
                <input type="text" className="form-control" placeholder=" Axtar " value={state?.contractEducationAbroadFilter?.common} onChange={(e) => setState({ ...state, contractEducationAbroadFilter: { ...state?.contractEducationAbroadFilter, common: e.target.value } })} />
                <span className="material-input" />
                </div>
                <button className="btn btn-white btn-round btn-just-icon" type="submit">
                  <i className="material-icons">search</i>
                  <div className="ripple-container" />
                </button>
              </div>
            </form>
          </div>

          {loading ? (
            <Loading />
          ) : (
            <div className="table-responsive">
              <div className="table-max-height">
                <table className="table">
                  <thead>
                    <tr style={{}}>
                      <th className="text-center">#</th>
                      <th>{t("contract.studentFullname")}</th>
                      <th>{t("contract.contractDate")}</th>
                      <th>{t("contract.university")}</th>
                      <th>{t("contract.contractAmount")}</th>
                      <th>{t("contract.contractNo")}</th>
                      <th>{t("contract.contractStatus")}</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <FilterTableRow page={"contractEducationAbroadFilter"} />
                    {contractEducationAbroads?.length > 0 ? (
                      contractEducationAbroads.map((item, i) => (
                        <tr key={item?.idContractEducationAbroad} onClick={() => isSelectRow(item?.idContractEducationAbroad)} className={selectedRow === item?.idContractEducationAbroad ? "selectedRow" : ""}>
                          <td className="text-center">{(pagination.pageIndex - 1) * localStorage.rowCount + (i + 1)}</td>
                          <td>{item?.studentFullName}</td>
                          <td>{item?.contractDate?.split("T")[0]}</td>
                          <td>{item?.university}</td>
                          <td>{item?.contractAmount ?? 0} ₼</td>
                          <td>{item?.contractNo}</td>
                          <td>
                            <span className={`contract-status-${item?.contractStatus}`}>{item?.contractStatus}</span>
                          </td>
                          <td className="td-actions text-right">
                            {item?.isExsistAttachment && (
                              <button className="btn btn-warning" onClick={() => (window.location = URL.BaseURL + `/file/contractEducationAbroads/downloadAll/14/${item.idContractEducationAbroad}`)}>
                                <i className="material-icons">download</i>
                              </button>
                            )}
                            <Link to={`${alias}${id}/contract/educationAbroad/view/${item?.idContractEducationAbroad}`}>
                              <button className="btn btn-info">
                                <i className="material-icons">visibility</i>
                              </button>
                            </Link>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <td colSpan={8}>
                        <NotFound />
                      </td>
                    )}
                  </tbody>
                </table>
              </div>
              <Pagination pagination={pagination} changePage={(pageIndex) => changePage(pageIndex)} rowText={t("tableRow")} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
