import { get, post, put, deleteData } from "./core/Requests";

export const getContractStudents = async (pageIndex = 0, pageSize = 0, filter) => {
  return await post(`/Contract/student/getList`, filter, { pageIndex, pageSize });
};

export const getContractStudentsByBranch = async (pageIndex, pageSize, branchId, filter) => {
  return await post(`/Contract/student/getByBranch/${branchId}`, filter ?? {}, {
    pageIndex,
    pageSize,
  });
};

export const getContractStudentsByStudent = async (pageIndex, pageSize, studentId) => {
  return await get(`/Contract/student/getByStudent/${studentId}`, {
    pageIndex,
    pageSize,
  });
};

export const getContractStudent = async (id) => {
  return await get(`/Contract/student/${id}`);
};

export const addContractStudent = async (data) => {
  return await post(`/Contract/student`, data);
};

export const updateContractStudent = async (data) => {
  return await put(`/Contract/student`, data);
};

export const deleteContractStudent = async (id) => {
  return await deleteData(`/Contract/student/${id}`);
};


export const getContractTrainersByBranch = async (pageIndex, pageSize, branchId, filter) => {
  return await post(`/Contract/trainer/getByBranch/${branchId}`, filter, {
    pageIndex,
    pageSize,
  });
};

export const getContractTrainersByTrainer = async (pageIndex, pageSize, trainerId) => {
  return await get(`/Contract/trainer/getByTrainer/${trainerId}`, {
    pageIndex,
    pageSize,
  });
};

export const getContractTrainerForSelect = async (trainerId) => {
  return await get(`/Contract/trainer/forSelect/${trainerId}`);
};

export const getContractTrainer = async (id) => {
  return await get(`/Contract/trainer/${id}`);
};

export const addContractTrainer = async (data) => {
  return await post(`/Contract/trainer`, data);
};

export const updateContractTrainer = async (data) => {
  return await put(`/Contract/trainer`, data);
};

export const deleteContractTrainer = async (id) => {
  return await deleteData(`/Contract/trainer/${id}`);
};



export const getContractJuridicalsByBranch = async (pageIndex, pageSize, branchId, filter) => {
  return await post(`/Contract/juridical/getByBranch/${branchId}`, filter, {
    pageIndex,
    pageSize,
  });
};

export const getContractJuridicalsByJuridicalPerson = async (pageIndex, pageSize, juridicalId) => {
  return await get(`/Contract/juridical/getByJuridical/${juridicalId}`, {
    pageIndex,
    pageSize,
  });
};

export const getContractJuridical = async (id) => {
  return await get(`/Contract/juridical/${id}`);
};

export const addContractJuridical = async (data) => {
  return await post(`/Contract/juridical`, data);
};

export const updateContractJuridical = async (data) => {
  return await put(`/Contract/juridical`, data);
};

export const deleteContractJuridical = async (id) => {
  return await deleteData(`/Contract/juridical/${id}`);
};



export const getContractEmployeesByBranch = async (pageIndex, pageSize, branchId, filter) => {
  return await post(`/Contract/employee/getByBranch/${branchId}`, filter, {
    pageIndex,
    pageSize,
  });
};

export const getContractEmployeesByEmployee = async (pageIndex, pageSize, employeeId) => {
  return await get(`/Contract/employee/getByEmployee/${employeeId}`, {
    pageIndex,
    pageSize,
  });
};

export const getContractEmployee = async (id) => {
  return await get(`/Contract/employee/${id}`);
};

export const addContractEmployee = async (data) => {
  return await post(`/Contract/employee`, data);
};

export const updateContractEmployee = async (data) => {
  return await put(`/Contract/employee`, data);
};

export const deleteContractEmployee = async (id) => {
  return await deleteData(`/Contract/employee/${id}`);
};


export const getContractEductionAbroads = async (pageIndex = 0, pageSize = 0, filter) => {
  return await post(`/Contract/educationAbroad/getList`, filter, { pageIndex, pageSize });
};

export const getContractEductionAbroadsByBranch = async (pageIndex, pageSize, branchId, filter) => {
  return await post(`/Contract/educationAbroad/getByBranch/${branchId}`, filter ?? {}, {
    pageIndex,
    pageSize,
  });
};

export const getContractEducationAbroadsByStudent = async (pageIndex, pageSize, studentId) => {
  return await get(`/Contract/educationAbroad/getByStudent/${studentId}`, {
    pageIndex,
    pageSize,
  });
};

export const getContractEducationAbroad = async (id) => {
  return await get(`/Contract/educationAbroad/${id}`);
};

export const addContractEducationAbroad = async (data) => {
  return await post(`/Contract/educationAbroad`, data);
};

export const updateContractEducationAbroad = async (data) => {
  return await put(`/Contract/educationAbroad`, data);
};

export const deleteContractEducationAbroad = async (id) => {
  return await deleteData(`/Contract/educationAbroad/${id}`);
};