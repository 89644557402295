import React, { useState, useEffect } from "react";
import { useMainContext } from "../../contexts/MainContext";
import Pagination from "../../components/pagination/Index";
import * as applicationService from "../../services/ApplicationService";
import Loading from "../../components/loading/Index";
import NotFound from "../../components/notfound/Index";
import { PAGE_INDEX, PAGE_SIZE_COUNTS } from "../../constants/Pagination";

import * as Alerts from "../../utils/Alerts";
import { useOrganization } from "../../helper/useOrganization";
import { Link } from "react-router-dom";
import PageSizeSelect from "../../components/pageSizeSelect/Index";
import * as date from "../../utils/DateNow";
import { useTokenData } from "../../helper/useTokenData";
import ActionButtons from "./../../components/actionButton/Index";
import { URL } from "./../../services/core/Urls";
import * as UserTypes from "./../../constants/UserType";
import AddCallBackModal from "./modal/AddCallBackModal";
import CallBackListModal from "./modal/CallBackListModal";

export default function Index() {
  const { state, setState } = useMainContext();
  const [applications, setApplications] = useState([]);
  const [selectedRow, setSelectedRow] = useState(0);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({});
  const [filter, setFilter] = useState({ startDate: date.Now(-30), endDate: date.Now() });
  const [isOpenCombo, setIsOpenCombo] = useState(false);
  const [isOpenCallBackListModal, setIsOpenCallBackListModal] = useState(false);
  const [isOpenAddCallBackModal, setIsOpenAddCallBackModal] = useState(false);
  const [tblFilter, setTblFilter] = useState(null);

  const alias = useOrganization();
  const tokenData = useTokenData();

  const isSelectRow = (rowId) => {
    rowId == selectedRow ? setSelectedRow(0) : setSelectedRow(rowId);
  };

  const handleCallBackListModal = (e) => {
    e.stopPropagation();
    if (isOpenCallBackListModal) setSelectedRow(0);
    setIsOpenCallBackListModal(!isOpenCallBackListModal);
  };

  const handleAddCallBackModal = () => {
    if (isOpenAddCallBackModal) {
      setSelectedRow(0);
      getApplications(PAGE_INDEX, localStorage.rowCount);
      }
    setIsOpenAddCallBackModal(!isOpenAddCallBackModal);
  };

  useEffect(() => {
    var common = "";
    if ((filter?.common == null || filter?.common == "") && state?.common != null) {
      common = state?.common;
    }

    setFilter({ startDate: date.Now(-30), endDate: date.Now(), common: common });
    getApplications(state.currentPage, localStorage.rowCount);
  }, [localStorage.branch]);

  useEffect(() => {
    if (tblFilter != null) {
      const timer = setTimeout(() => {
        getApplications(PAGE_INDEX, localStorage.rowCount);
      }, 1500);

      return () => clearTimeout(timer);
    }
  }, [tblFilter]);

  const getApplications = async (pageIndex, pageSize) => {
    let newfilter = { ...filter, ...tblFilter };
    setState({ ...state, applicationFilter: newfilter });
    if ((filter?.common == null || filter?.common == "") && state?.common != null) {
      newfilter.common = state?.common;
    }
    setLoading(true);
    if (localStorage.branch != undefined && localStorage.branch != "null") {
      let result = await applicationService.getApplicationsByBranch(localStorage.branch, pageIndex, pageSize, newfilter);
      if (result.success) {
        setApplications(result.data.datas);
        setPagination({
          pageIndex: result.data.pageIndex,
          hasNextPage: result.data.hasNextPage,
          hasPreviousPage: result.data.hasPreviousPage,
          totalPageCount: result.data.totalPageCount,
          totalRecordCount: result.data.totalRecordCount,
        });
        setLoading(false);
      } else {
        Alerts.error(result.message);
      }
    }
  };

  const deleteApplication = async (id) => {
    await Alerts.confirmForDelete(async function (confirmed) {
      if (confirmed) {
        let result = await applicationService.deleteApplication(id);
        if (result.success) {
          setSelectedRow(0);
          Alerts.success(result.message);
        } else {
          Alerts.error(result.message);
        }
        getApplications(pagination.pageIndex, localStorage.rowCount);
      }
    });
  };

  const changeConvertToContract = async (idApplication) => {
    await Alerts.confirmForChange(async function (confirmed) {
      if (confirmed) {
        let result = await applicationService.changeConvertToContract(idApplication);
        if (result.success) {
          Alerts.success(result.message);
        } else {
          Alerts.error(result.message);
        }
        getApplications(pagination.pageIndex, localStorage.rowCount);
      }
    });
  };

  const Search = (e) => {
    e.preventDefault();
    getApplications(PAGE_INDEX, localStorage.rowCount);
  };

  const changePage = (pageIndex) => {
    setState({ ...state, currentPage: pageIndex });
    getApplications(pageIndex, localStorage.rowCount);
  };


  return (
    <div className="col-md-12">
      {tokenData.userTypeId != UserTypes.STUDENT_USER && <ActionButtons deletePath={`application/delete`} editPath={`application/edit`} addPath={`application/add`} deleteRow={deleteApplication} selectedRow={selectedRow} isExsistOtherBtn={true} exportPath={"application/export"} />}

      <div className="card" data-background-color={state.theme}>
        <div className="card-header card-header-icon" data-background-color={state.theme}>
          <i className="material-icons">assignment_ind</i>
        </div>
        <div className="card-content">
          <h4 className="card-title">Müraciətlərin siyahısı</h4>

          <div className="row">
            <div className="col-md-4 f-left">
              <PageSizeSelect onChange={(e) => getApplications(PAGE_INDEX, e.value)} />
            </div>
            <form onSubmit={Search}>
              <div className="navbar-form col-md-2 f-right">
                <div className="form-group navbar-form">
                  <input type="date" className="form-control" value={filter?.startDate ?? ""} onChange={(e) => setFilter({ ...filter, startDate: e.target.value == "" ? null : e.target.value })} />
                  <input type="date" className="form-control ml-10" value={filter?.endDate ?? ""} onChange={(e) => setFilter({ ...filter, endDate: e.target.value == "" ? null : e.target.value })} />
                  <input
                    type="text"
                    className="form-control ml-10"
                    placeholder=" Axtar "
                    value={filter?.common ?? ""}
                    onChange={(e) => {
                      setFilter({ ...filter, common: e.target.value });
                      setState({ ...state, common: e.target.value });
                    }}
                  />
                  <span className="material-input" />
                </div>
                <button className="btn btn-white btn-round btn-just-icon" type="submit">
                  <i className="material-icons">search</i>
                  <div className="ripple-container" />
                </button>
              </div>
            </form>
          </div>

          {loading ? (
            <Loading />
          ) : (
            <div className="table-responsive">
              <div className="table-max-height">
                <table className="table">
                  <thead>
                    <tr>
                      <th className="text-center">#</th>
                      <th>Tam adı</th>
                      <th>Tarix</th>
                      <th>Əlaqə nömrəsi</th>
                      <th>Müraciət forması</th>
                      <th>Referans</th>
                      <th>Maraqlanılan kurs</th>
                      <th>Uğur ehtimalı</th>
                      <th>Status</th>
                      <th>Uğurlu nəticə</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="td-actions text-right">
                        <button
                          className="btn btn-danger"
                          onClick={(e) => {
                            setTblFilter({});
                          }}
                        >
                          <i className="material-icons">search_off</i>
                        </button>
                      </td>
                      <td>
                        <div class="input-wrapper">
                          <input type="text" value={tblFilter?.fullName} onChange={(e) => setTblFilter({ ...tblFilter, fullName: e.target.value })} />
                          <i className="material-icons material-icons-input">search</i>
                        </div>
                      </td>
                      <td>
                        <div class="input-wrapper">
                          <input type="text" value={tblFilter?.applicationDate} onChange={(e) => setTblFilter({ ...tblFilter, applicationDate: e.target.value })} />
                          <i className="material-icons material-icons-input">search</i>
                        </div>
                      </td>
                      <td>
                        <div class="input-wrapper">
                          <input type="text" value={tblFilter?.phoneNumber} onChange={(e) => setTblFilter({ ...tblFilter, phoneNumber: e.target.value })} />
                          <i className="material-icons material-icons-input">search</i>
                        </div>
                      </td>
                      <td>
                        <div class="input-wrapper">
                          <input type="text" value={tblFilter?.applicationType} onChange={(e) => setTblFilter({ ...tblFilter, applicationType: e.target.value })} />
                          <i className="material-icons material-icons-input">search</i>
                        </div>
                      </td>
                      <td>
                        <div class="input-wrapper">
                          <input type="text" value={tblFilter?.referance} onChange={(e) => setTblFilter({ ...tblFilter, referance: e.target.value })} />
                          <i className="material-icons material-icons-input">search</i>
                        </div>
                      </td>
                      <td>
                        <div class="input-wrapper">
                          <input type="text" value={tblFilter?.trainingType} onChange={(e) => setTblFilter({ ...tblFilter, trainingType: e.target.value })} />
                          <i className="material-icons material-icons-input">search</i>
                        </div>
                      </td>
                      <td>
                        <div class="input-wrapper">
                          <input type="text" value={tblFilter?.probability} onChange={(e) => setTblFilter({ ...tblFilter, probability: e.target.value })} />
                          <i className="material-icons material-icons-input">search</i>
                        </div>
                      </td>
                      <td>
                        <div class="input-wrapper">
                          <input type="text" value={tblFilter?.status} onChange={(e) => setTblFilter({ ...tblFilter, status: e.target.value })} />
                          <i className="material-icons material-icons-input">search</i>
                        </div>
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    {applications?.length > 0 ? (
                      applications.map((item, i) => (
                        <tr key={item?.idApplication} onClick={() => isSelectRow(item?.idApplication)} className={selectedRow === item?.idApplication ? "selectedRow" : ""}>
                          <td className="text-center">{(pagination.pageIndex - 1) * localStorage.rowCount + (i + 1)}</td>
                          <td>{item?.fullName}</td>
                          <td>{item?.applicationDate}</td>
                          <td>{item?.phoneNumber}</td>
                          <td>{item?.applicationType}</td>
                          <td>{item?.referance}</td>
                          <td>{item?.trainingType}</td>
                          <td>{item?.probability} %</td>
                          <td>
                            <p style={{ borderRadius: 5, margin: 0, padding: 5, color: "white", backgroundColor: `${item?.statusColor}` }}>{item?.status}</p>
                          </td>
                          <td>
                            <div className="togglebutton">
                              <label>
                                <input type="checkbox" checked={item?.convertedToContract} onChange={() => changeConvertToContract(item?.idApplication)} />
                                <span className="toggle" />
                              </label>
                            </div>
                          </td>
                          <td className="td-actions text-right min-width-column">
                            {-1 !== JSON.parse(localStorage?.menuPrivilages).findIndex((obj) => obj.menuName && obj.menuName.toLowerCase() == "application/addcallbackresult") ? (
                              <button
                                title="Zəng qeydi"
                                className="btn btn-success"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setSelectedRow(item?.idApplication);
                                  handleAddCallBackModal(e);
                                }}
                              >
                                <i className="material-icons">phone_forwarded</i>
                              </button>
                            ) : null}
                            <button
                              title="Zənglər"
                              className="btn btn-warning"
                              onClick={(e) => {
                                setSelectedRow(item?.idApplication);
                                handleCallBackListModal(e);
                              }}
                            >
                              <i className="material-icons">format_list_bulleted</i>
                            </button>
                            <Link to={`${alias}/application/view/${item?.idApplication}`}>
                              <button className="btn btn-info">
                                <i className="material-icons">visibility</i>
                              </button>
                            </Link>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <td colSpan={10}>
                        <NotFound />
                      </td>
                    )}
                  </tbody>
                </table>
              </div>
              <Pagination pagination={pagination} changePage={(pageIndex) => changePage(pageIndex)} />
            </div>
          )}
        </div>
      </div>
      {isOpenAddCallBackModal ? <AddCallBackModal isOpen={isOpenAddCallBackModal} handleViewModal={handleAddCallBackModal} header={"Əlaqə məlumatı"} applicationId={selectedRow} /> : null}
      {isOpenCallBackListModal ? <CallBackListModal isOpen={isOpenCallBackListModal} handleViewModal={handleCallBackListModal} header={"Əlaqə məlumatları"} applicationId={selectedRow} /> : null}
    </div>
  );
}
