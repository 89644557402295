import React, { useState, useEffect } from "react";
import { useMainContext } from "./../../contexts/MainContext";
import Pagination from "./../../components/pagination/Index";
import * as financeOperationService from "./../../services/FinanceOperationService";
import { PAGE_INDEX, PAGE_SIZE_COUNTS } from "../../constants/Pagination";

import * as Alerts from "./../../utils/Alerts";
import { useOrganization } from "../../helper/useOrganization";
import Loading from "./../../components/loading/Index";
import NotFound from "./../../components/notfound/Index";
import { useParams, Link } from "react-router-dom";
import PageSizeSelect from "./../../components/pageSizeSelect/Index";
import * as date from "./../../utils/DateNow";
import { useTokenData } from "../../helper/useTokenData";
import { useStudent } from "../../helper/useStudent";
import ActionButtons from "./../../components/actionButton/Index";
import * as UserTypes from "./../../constants/UserType";
import { URL } from "./../../services/core/Urls";

export default function Index() {
  const { state, setState } = useMainContext();
  const [financeOperations, setFinanceOperations] = useState([]);
  const [selectedRow, setSelectedRow] = useState(0);
  // const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState({});
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState({ startDate: date.Now(-30), endDate: date.Now() });

  const { idFinanceOperationType } = useParams();

  if (idFinanceOperationType != "1-2" && (isNaN(idFinanceOperationType) || idFinanceOperationType < 2 || idFinanceOperationType > 11)) window.location = "https://e-edu.az";
  const alias = useOrganization();
  const id = useStudent();
  const tokenData = useTokenData();

  const isSelectRow = (rowId) => {
    rowId == selectedRow ? setSelectedRow(0) : setSelectedRow(rowId);
  };

  useEffect(() => {
    //localStorage.getItem("@page")
    // setFilter({});
    setFilter({ startDate: date.Now(-30), endDate: date.Now() });
    getFinanceOperation(state.currentPage, localStorage.rowCount);
  }, [localStorage.branch, idFinanceOperationType]);

  const getFinanceOperation = async (pageIndex, pageSize) => {
    setLoading(true);
    if (localStorage.branch != undefined && localStorage.branch != "null") {
      let result = await financeOperationService.getFinanceOperationsByBranch(idFinanceOperationType, localStorage.branch, pageIndex, pageSize, filter);
      if (result.success) {
        setFinanceOperations(result.data.datas);
        setPagination({
          pageIndex: result.data.pageIndex,
          hasNextPage: result.data.hasNextPage,
          hasPreviousPage: result.data.hasPreviousPage,
          totalPageCount: result.data.totalPageCount,
          totalRecordCount: result.data.totalRecordCount,
        });
        setLoading(false);
      } else {
        Alerts.error(result.message);
      }
    }
  };

  const deleteFinanceOperation = async (id) => {
    await Alerts.confirmForDelete(async function (confirmed) {
      if (confirmed) {
        let result = await financeOperationService.deleteFinanceOperation(id);
        if (result.success) {
          setSelectedRow(0);
          Alerts.success(result.message);
        } else {
          Alerts.error(result.message);
        }
        getFinanceOperation(pagination.pageIndex, localStorage.rowCount);
      }
    });
  };

  const Search = (e) => {
    e.preventDefault();
    getFinanceOperation(PAGE_INDEX, localStorage.rowCount);
  };

  const changePage = (pageIndex) => {
    setState({ ...state, currentPage: pageIndex });
    getFinanceOperation(pageIndex, localStorage.rowCount);
  };

  return (
    <div className="col-md-12">
      {tokenData.userTypeId != UserTypes.STUDENT_USER && <ActionButtons deletePath={`financeOperation/${idFinanceOperationType}/delete`} editPath={`financeOperation/${idFinanceOperationType}/edit`} addPath={`financeOperation/${idFinanceOperationType}/add`} deleteRow={deleteFinanceOperation} selectedRow={selectedRow} isExsistOtherBtn={true} exportPath={`financeOperation/${idFinanceOperationType == "1-2" ? "1" : idFinanceOperationType}/export`} />}
      <div className="card" data-background-color={state.theme}>
        <div className="card-header card-header-icon" data-background-color={state.theme}>
          <i className="material-icons">paid</i>
        </div>
        <div className="card-content">
          <h4 className="card-title" data-color={state.theme}>
            {idFinanceOperationType == "1-2" ? "Kassa əməliyyatlarının " : idFinanceOperationType == 3 ? "Tələbə " : idFinanceOperationType == 4 ? "Təlimçi " : idFinanceOperationType == 5 ? "Əməkdaş " : idFinanceOperationType == "6" ? "Xərc " : idFinanceOperationType == "7" ? "Əlavə gəlir " : idFinanceOperationType == "8" ? "Kassa əməliyyatlarının " : idFinanceOperationType == "9" ? "Hüquqi şəxs ": "Xaricdə təhsil "} {idFinanceOperationType != "1-2" && "ödənişlərinin"} siyahısı
          </h4>

          {/* <PageSizeSelect onChange={(e) => getFinanceOperation(PAGE_INDEX, e.value)} /> */}

          <div className="row">
            <div className="col-md-4 f-left">
              <PageSizeSelect onChange={(e) => getFinanceOperation(PAGE_INDEX, e.value)} />
            </div>
            <form onSubmit={Search}>
              <div className="navbar-form col-md-2 f-right">
                <div className="form-group navbar-form">
                  <input type="date" className="form-control" value={filter?.startDate ?? ""} onChange={(e) => setFilter({ ...filter, startDate: e.target.value == "" ? null : e.target.value })} />
                  <input type="date" className="form-control ml-10" value={filter?.endDate ?? ""} onChange={(e) => setFilter({ ...filter, endDate: e.target.value == "" ? null : e.target.value })} />

                  <input type="text" className="form-control ml-10" placeholder=" Axtar " value={filter?.common ?? ""} onChange={(e) => setFilter({ ...filter, common: e.target.value })} />
                  <span className="material-input" />
                </div>
                <button className="btn btn-white btn-round btn-just-icon" type="submit">
                  <i className="material-icons">search</i>
                  <div className="ripple-container" />
                </button>
              </div>
            </form>
          </div>

          {loading ? (
            <Loading />
          ) : financeOperations?.length > 0 ? (
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th className="text-center">#</th>
                    <th>{idFinanceOperationType == 3 || idFinanceOperationType == 10 ? "Tələbə" : idFinanceOperationType == 4 ? "Təlimçi" : idFinanceOperationType == 5 ? "Əməkdaş" : idFinanceOperationType == 6 ? "Xərc adı" : idFinanceOperationType == 7 ? "Əlavə gəlir adı" : idFinanceOperationType == 9 ? "Hüquqi şəxs adı" : "Əməliyyat növü"}</th>
                    {idFinanceOperationType != 4 ? <th>{idFinanceOperationType == 5 ? "Əməkdaş ödəniş növü" : idFinanceOperationType == 6 ? "Xərc ödəniş növü" : idFinanceOperationType == 3 || idFinanceOperationType == 9 || idFinanceOperationType == 10 ? "Müqavilə" : ""}</th> : null}
                    <th>Məbləğ</th>
                    <th>Tarix</th>
                    <th>İstifadəçi</th>
                    <th>Daxil edilmə tarixi</th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {financeOperations.map((item, i) => (
                    <tr key={item?.idFinanceOperation} onClick={() => isSelectRow(item?.idFinanceOperation)} className={selectedRow === item?.idFinanceOperation ? "selectedRow" : ""}>
                      <td className="text-center">{(pagination.pageIndex - 1) * localStorage.rowCount + (i + 1)}</td>
                      {idFinanceOperationType == 3 || idFinanceOperationType == 10 ? <td>{`${item?.student?.fullName} `}</td> : idFinanceOperationType == 4 || idFinanceOperationType == 5 ? <td>{`${item?.trainer?.surname} ${item?.trainer?.name} ${item?.trainer?.middleName} `}</td> : idFinanceOperationType == 6 ? <td>{item?.expense?.description}</td> : idFinanceOperationType == 7 ? <td>{item?.income?.description}</td> : idFinanceOperationType == 9 ? <td>{item?.juridicalPerson?.name}</td> : <td>{item?.financeOperationType}</td>}
                      {idFinanceOperationType == 3 ? <td>{item?.contractStudent}</td> : idFinanceOperationType == 5 ? <td>{item?.employeePaymentTypeName}</td> : idFinanceOperationType == 9 ? <td>{item?.contractJuridical}</td> : idFinanceOperationType == 6 ? <td>{item?.expense?.expenseType}</td> : idFinanceOperationType == 10 ? <td>{item?.contractEducationAbroad}</td> : null}
                      <td>{item?.amount}</td>
                      <td>{item?.financeOperationDate?.split("T")[0]}</td>
                      <td>{item?.user?.username}</td>
                      <td>{item?.insertDate}</td>

                      <td className="td-actions text-right">
                        <button disabled={item?.fileCount == 0} className="btn btn-warning" onClick={() => (window.location = URL.BaseURL + `/file/financeOperations/downloadAll/13/${item.idFinanceOperation}`)}>
                          <i className="material-icons">download</i>
                        </button>
                        <Link to={`${alias}${id}/financeOperation/${idFinanceOperationType}/view/${item?.idFinanceOperation}`}>
                          <button className="btn btn-info">
                            <i className="material-icons">visibility</i>
                          </button>
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Pagination pagination={pagination} changePage={(pageIndex) => changePage(pageIndex)} />
            </div>
          ) : (
            <NotFound />
          )}
        </div>
      </div>
    </div>
  );
}
